import UIFullScreenContent from 'components/ui/screenContent/FullScreenContent';
import React from 'react';

const InvalidResultErrorPage = () => (
  <UIFullScreenContent
    header='Invalid result'
    description={
      <>Please open a valid result or contact Methinks Support if the problem persists</>
    }
    useSymbol={true}
  />
);

export default InvalidResultErrorPage;
