import React from 'react';
import { SliderValue } from 'types';
import { handleJumpToSlice, IncrementSliceByDelta } from '../../../utils/utilities';
import { ArrowIcon } from '../icons';
import './Slider.css';

const UISlider = ({
  setSliderValue,
  value,
}: {
  setSliderValue: React.Dispatch<React.SetStateAction<SliderValue>>;
  value: SliderValue;
}) => (
  <div className='slider-container'>
    <div
      className='arrow-icon'
      onClick={() => IncrementSliceByDelta(-1, setSliderValue)}
    >
      <ArrowIcon />
    </div>
    <input
      type='range'
      id='volume-slider'
      value={value.currentSlice}
      min={0}
      max={value.maxSlice - 1}
      onChange={async (e) => {
        const newValue = Number(e.target.value);
        await handleJumpToSlice(newValue, setSliderValue);
      }}
    />
    <div
      className='rotate-180 arrow-icon'
      onClick={() => IncrementSliceByDelta(1, setSliderValue)}
    >
      <ArrowIcon />
    </div>
  </div>
);

export default UISlider;
