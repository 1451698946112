import React from 'react';
import './Spinner.css';

type SpinnerProps = {
  loadingProgress: number;
}

const Spinner: React.FC<SpinnerProps> = ({ loadingProgress: progress }) => (
  <div className='spinner-container'>
    <div className='spinner'></div>
    <div className='loading-progress'>{progress}%</div>
  </div>
);

export default Spinner;
