import React from 'react';

const StackIcon: React.FC = () => (
  <>
    <svg width='12' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1172_48481)'>
        <path
          d='M6.11768 1L1.11768 3.5L6.11768 6L11.1177 3.5L6.11768 1Z'
          stroke='white'
          strokeOpacity='0.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.11768 8.5L6.11768 11L11.1177 8.5'
          stroke='white'
          strokeOpacity='0.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.11768 6L6.11768 8.5L11.1177 6'
          stroke='white'
          strokeOpacity='0.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1172_48481'>
          <rect width='12' height='12' fill='white' transform='translate(0.117676)' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default StackIcon;
