import React from 'react';
import UIFullScreenContent from 'components/ui/screenContent/FullScreenContent';

export default function ConfigPage() {
  return (
    <UIFullScreenContent
      header='Welcome to Methinks AI. Let’s improve stroke care together.'
      description={
        <>
          <p>
            An active subscription is required. Contact{' '}
            <a href='mailto:info@methinks.ai'>info@methinks.ai</a> if you need one.
          </p>
          <p>
            For support, please email us at
            <a href='mailto:support@methinks.ai'> support@methinks.ai</a>.
          </p>
        </>
      }
      useSymbol={false}
    />
  );
}
