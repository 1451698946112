import React from 'react';

const ErrorCross: React.FC = () => (
  <>
    <svg
      width='89'
      height='88'
      viewBox='0 0 89 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_210_6024)'>
        <rect x='4.5' width='80' height='80' rx='40' fill='#1A1A1A' />
        <path
          d='M53.3886 31.1113L35.6108 48.8891'
          stroke='white'
          strokeWidth='1.63636'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.6108 31.1113L53.3886 48.8891'
          stroke='white'
          strokeWidth='1.63636'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M43.3894 62.0375C56.1737 62.0375 66.5375 51.6737 66.5375 38.8894C66.5375 26.105 56.1737 15.7412 43.3894 15.7412C30.605 15.7412 20.2412 26.105 20.2412 38.8894C20.2412 51.6737 30.605 62.0375 43.3894 62.0375Z'
          stroke='white'
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_210_6024'
          x='0.5'
          y='0'
          width='88'
          height='88'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_210_6024'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_210_6024'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  </>
);

export default ErrorCross;
