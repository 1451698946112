import React from 'react';
import UIFullScreenContent, { FullScreenContentProps } from 'components/ui/screenContent/FullScreenContent';
import { requestAccess } from 'utils/requests';

export default function AuthenticationErrorPage() {
  const [requestSent, setRequestSent] = React.useState(false);
  const [authErrorContent, setAuthErrorContent] = React.useState<FullScreenContentProps>({
    header: 'You dont have access to this institution',
    description: (
      <>
        <p>Please request access and wait for Methinks approval.</p>
        <p>Note: Access approval is specific to your institution.</p>
      </>
    ),
    useSymbol: true,
  });

  const handleRequestAccess = async () => {
    await requestAccess();
    setAuthErrorContent({
      header: 'Access has been requested successfully',
      description: (
        <>
          <p>Please wait for Methinks approval</p>
          <p>
            For support, please contact us at <a href='mailto:info@methinks.ai'>info@methinks.ai</a>
            .
          </p>
        </>
      ),
    });
    setRequestSent(true);
  };

  return (
    <UIFullScreenContent
      header={authErrorContent.header}
      description={authErrorContent.description}
      useSymbol={authErrorContent.useSymbol}
      actions={
        !requestSent
          ? [
            {
              label: 'Request Access',
              className: 'action-button',
              onClick: handleRequestAccess,
            },
          ]
          : []
      }
    />
  );
}
