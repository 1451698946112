import { authentication } from '@microsoft/teams-js';

export default async function getCaseData(token: string, diagnosticId: string, env: string) {
  try {
    const endpoint = process.env[`REACT_APP_STROKE_ENDPOINT_${env}`];
    const response = await fetch(`${endpoint}/ms_teams_viewer/v2/viewer_data`, {
      method: 'POST',
      headers: {
        key: '1234',
        token,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        diag_id: diagnosticId,
      }),
    });

    return response;
  } catch (error) {
    console.error('Error fetching case data:', error);
    throw error;
  }
}

export async function requestAccess() {
  try {
    const params = new URLSearchParams(window.location.search);
    const diagnosticId = params.get('diagnostic_id');
    const env = params.get('env');
    const endpoint = process.env[`REACT_APP_STROKE_ENDPOINT_${env}`];

    authentication.getAuthToken({
      successCallback: async (token) => {
        const response = await fetch(`${endpoint}/ms_teams_viewer/request_access`, {
          method: 'POST',
          headers: {
            key: '1234',
            token,
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({ diag_id: diagnosticId }),
        });
        return response.json();
      },
      failureCallback: (err) => {
        console.error('Error requesting access:', err);
      },
    });
  } catch (error) {
    console.error('Error requesting access:', error);
  }
}
