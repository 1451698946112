import './SelectionMenu.css';
import React, { useState, useEffect } from 'react';
import areStringsEqual from 'utils/comparisons';

type MenuOption = {
  title: string;
  onClick: () => void;
  className?: string;
};

type UISelectMenuProps = {
  activeIcon: React.ReactNode;
  menuContent?: MenuOption[];
  exitIcon?: {
    icon: React.ReactNode;
    onClick: () => void;
  };
  menuItemClassName?: string;
  id?: string;
  activeItem?: string;
  isToggleMenu?: boolean;
};

const UISelectionMenu = ({
  menuContent,
  activeIcon,
  exitIcon,
  menuItemClassName,
  id,
  activeItem,
  isToggleMenu = false,
}: UISelectMenuProps) => {
  const [selectedItem, setSelectedItem] = useState(activeItem || '');

  useEffect(() => {
    setSelectedItem(activeItem || '');
  }, [activeItem]);

  const handleSelectItem = (option: MenuOption) => {
    if (isToggleMenu) {
      setSelectedItem((prevSelected) => (areStringsEqual(prevSelected, option.title) ? '' : option.title));
    } else {
      setSelectedItem(option.title);
    }
    option.onClick();
  };

  return (
    <div className={'selection-menu'} id={id}>
      <div className='selection-icon'>{activeIcon}</div>
      {menuContent.map((option) => (
        <p
          key={option.title}
          className={`selection-button ${menuItemClassName} ${
            areStringsEqual(selectedItem, option.title) ? 'active' : ''
          } ${option.className || ''}`}
          onClick={() => handleSelectItem(option)}
        >

          {option.title.charAt(0).toUpperCase() + option.title.slice(1)}
        </p>
      ))}
      {exitIcon && (
        <p onClick={() => exitIcon.onClick()} className='selection-icon'>
          {exitIcon.icon}
        </p>
      )}
    </div>
  );
};

export default UISelectionMenu;
