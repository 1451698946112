import React from 'react';

interface FunctionalTextProps {
  text: string;
  onClick: () => void;
  className?: string;
}

export default function FunctionalText({ text, onClick, className }: FunctionalTextProps) {
  return <p className={className} onClick={onClick}>{text}</p>;
}
