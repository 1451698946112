import DefaultMenuCanvas from 'components/menuInputs/DefaultMenuCanvas';
import React, { useEffect, useState } from 'react';
import './ViewerMenu.css';
import { useWindowing } from 'context/Windowing';

export default function ViewerMenu() {
  const [topBar, setTopBar] = useState<React.ReactNode>(null);

  const { windowingValues } = useWindowing();

  useEffect(() => {
    setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
  }, []);

  return (
    <>
      {topBar}
      <div className='windowing-stats'>
        WW/WL: {windowingValues.windowWidth.toFixed(0)}
        /
        {windowingValues.windowCenter.toFixed(0)}
      </div>
    </>
  );
}
