import React from 'react';

const CrossIcon: React.FC = () => (
  <>
    <svg width='16' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.818 4L4.81799 12'
        stroke='white'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.81799 4L12.818 12'
        stroke='white'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
);

export default CrossIcon;
