import React from 'react';

export const HospitalIcon = (
  <>
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 11.125H13.875V6C13.875 5.76794 13.7828 5.54538 13.6187 5.38128C13.4546 5.21719 13.2321 5.125 13 5.125H9.375V1C9.375 0.767936 9.28281 0.545376 9.11872 0.381282C8.95462 0.217187 8.73206 0.125 8.5 0.125H2.5C2.26794 0.125 2.04538 0.217187 1.88128 0.381282C1.71719 0.545376 1.625 0.767936 1.625 1V11.125H1C0.900544 11.125 0.805161 11.1645 0.734835 11.2348C0.664509 11.3052 0.625 11.4005 0.625 11.5C0.625 11.5995 0.664509 11.6948 0.734835 11.7652C0.805161 11.8355 0.900544 11.875 1 11.875H14.5C14.5995 11.875 14.6948 11.8355 14.7652 11.7652C14.8355 11.6948 14.875 11.5995 14.875 11.5C14.875 11.4005 14.8355 11.3052 14.7652 11.2348C14.6948 11.1645 14.5995 11.125 14.5 11.125ZM13 5.875C13.0332 5.875 13.0649 5.88817 13.0884 5.91161C13.1118 5.93505 13.125 5.96685 13.125 6V11.125H9.375V5.875H13ZM2.375 1C2.375 0.966848 2.38817 0.935054 2.41161 0.911612C2.43505 0.88817 2.46685 0.875 2.5 0.875H8.5C8.53315 0.875 8.56495 0.88817 8.58839 0.911612C8.61183 0.935054 8.625 0.966848 8.625 1V11.125H7.375V8C7.375 7.90054 7.33549 7.80516 7.26517 7.73483C7.19484 7.66451 7.09946 7.625 7 7.625H4C3.90054 7.625 3.80516 7.66451 3.73484 7.73483C3.66451 7.80516 3.625 7.90054 3.625 8V11.125H2.375V1ZM6.625 11.125H4.375V8.375H6.625V11.125ZM3.625 4C3.625 3.90054 3.66451 3.80516 3.73484 3.73484C3.80516 3.66451 3.90054 3.625 4 3.625H5.125V2.5C5.125 2.40054 5.16451 2.30516 5.23484 2.23484C5.30516 2.16451 5.40054 2.125 5.5 2.125C5.59946 2.125 5.69484 2.16451 5.76516 2.23484C5.83549 2.30516 5.875 2.40054 5.875 2.5V3.625H7C7.09946 3.625 7.19484 3.66451 7.26517 3.73484C7.33549 3.80516 7.375 3.90054 7.375 4C7.375 4.09946 7.33549 4.19484 7.26517 4.26516C7.19484 4.33549 7.09946 4.375 7 4.375H5.875V5.5C5.875 5.59946 5.83549 5.69484 5.76516 5.76516C5.69484 5.83549 5.59946 5.875 5.5 5.875C5.40054 5.875 5.30516 5.83549 5.23484 5.76516C5.16451 5.69484 5.125 5.59946 5.125 5.5V4.375H4C3.90054 4.375 3.80516 4.33549 3.73484 4.26516C3.66451 4.19484 3.625 4.09946 3.625 4Z"
        fill="#A6B2CB"
      />
    </svg>
  </>
);

export default HospitalIcon;
