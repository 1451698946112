import React from 'react';

const IschemiaIcon: React.FC = () => (
  <div>
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.181885' y='0.181641' width='20' height='20' rx='10' fill='url(#paint0_radial_4009_37907)' />
      <defs>
        <radialGradient
          id='paint0_radial_4009_37907'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(10.1819 10.1816) rotate(-45) scale(15.9099)'
        >
          <stop stopColor='#FE0801' />
          <stop offset='0.304245' stopColor='#EFB625' />
          <stop offset='0.641857' stopColor='#2BDEDE' />
          <stop offset='1' stopColor='#000AFF' />
        </radialGradient>
      </defs>
    </svg>
  </div>
);

export default IschemiaIcon;
