import React from 'react';

type SolidColorIconProps = {
  color: string;
  className?: string;
};

const SolidColorIcon: React.FC<SolidColorIconProps> = ({ color, className }) => (
  <div className={className}>
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.181885' y='0.181641' width='20' height='20' rx='10' fill={color} />
    </svg>
  </div>
);

export default SolidColorIcon;
