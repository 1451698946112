import React from 'react';
import { ErrorCross } from 'components/ui/icons';
import './FullScreenContent.css';

export type FullScreenContentProps = {
  header: string;
  description?: React.ReactNode;
  useSymbol?: boolean;
  actions?: Array<{
    label: string | React.ReactNode;
    onClick: () => void;
    className?: string;
  }>;
};

const UIFullScreenContent: React.FC<FullScreenContentProps> = ({
  header,
  description,
  useSymbol,
  actions,
}) => {
  const mappedActions = actions?.map(
    ({ label, onClick, className }, index) => (
      <div key={index} className={`${className || ''}`} onClick={onClick}>
        {label}
      </div>
    ),
  );

  return (
    <div className='screen-message'>
      {useSymbol && <ErrorCross />}
      <h1 className='screen-message-header'>{header}</h1>
      {description && (
        <div className='screen-message-message'>{description}</div>
      )}
      {mappedActions}
    </div>
  );
};

export default UIFullScreenContent;
