import React, { ReactNode, createContext, useContext, useState } from 'react';
import { WindowingValue } from 'types';

type WindowingContextType = {
  windowingValues: WindowingValue;
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>;
};

const WindowingContext = createContext<WindowingContextType | undefined>(undefined);

export const WindowingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [windowingValues, setWindowingValues] = useState<WindowingValue>({
    windowCenter: 0,
    windowWidth: 0,
  });

  return (
    <WindowingContext.Provider value={{
      windowingValues,
      setWindowingValues,
    }}>{children}</WindowingContext.Provider>
  );
};

export const useWindowing = () => {
  const context = useContext(WindowingContext);
  if (!context) {
    throw new Error('useWindowing must be used within a WindowingProvider');
  }
  return context;
};
