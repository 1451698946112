import { Enums, getRenderingEngine, setVolumesForViewports } from '@cornerstonejs/core';
import { ToolGroupManager, utilities } from '@cornerstonejs/tools';
import React from 'react';
import { ImageOrientation, Lesions, WindowingValue } from 'types';
import { setTransferFunctionForVolumeActor } from 'utils/cornerstone/setup';
import { DEFAULT_CTA_VALUES,
  DEFAULT_NCCT_VALUES,
  IMAGE_TYPES,
  RENDERING_ENGINE_ID,
  TOOL_GROUP_ID,
  VIEWPORT_ID } from '../constants';
import { calculateSliceValues, calculateWindowingValues } from './cornerstone/tooling';
import { applyColorMaps, removeColorMaps } from './heatmaps/heatmaps';

export const getViewport = () => {
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  return renderingEngine ? renderingEngine.getViewport(VIEWPORT_ID) : null;
};

export const updateVolumeWindow = async (windowWidth: number, windowCenter: number) => {
  const transferFunction = getViewport()
    .getActors()[0]
    .actor.getProperty()
    .getRGBTransferFunction(0);
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

  transferFunction.setMappingRange(windowCenter - windowWidth / 2, windowCenter + windowWidth / 2);
  renderingEngine?.render();
};

export const IncrementSliceByDelta = async (delta: number, setSliderValue: any) => {
  const viewport = getViewport();
  utilities.scroll(viewport!, { delta });
  viewport?.render();

  setSliderValue(calculateSliceValues());
};

export const handleJumpToSlice = async (
  sliceIndex: number,
  setSliderValue: React.Dispatch<React.SetStateAction<{ currentSlice: number; maxSlice: number }>>,
) => {
  const viewport = getViewport();
  await utilities.jumpToSlice(viewport!.element, {
    imageIndex: sliceIndex,
  });
  viewport?.render();

  setSliderValue(calculateSliceValues());
};

export const getDefaultWindowingValues = (volumeId: string): WindowingValue => {
  if (volumeId.toLowerCase().includes(IMAGE_TYPES.NCCT.toLowerCase())) {
    return DEFAULT_NCCT_VALUES;
  }
  return DEFAULT_CTA_VALUES;
};

export const resetFilters = (
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>,
) => {
  const viewport = getViewport();
  viewport?.setPan([0, 0]);
  const { windowWidth, windowCenter } = getDefaultWindowingValues(viewport.getActors()[0].uid);
  viewport?.setZoom(1);
  setWindowingValues({ windowWidth, windowCenter });
  return updateVolumeWindow(windowWidth, windowCenter);
};

export const handleResize = (resetZoom?: boolean) => {
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  const viewport = getViewport();
  renderingEngine?.resize(true);
  viewport?.setPan([0, 0]);
  if (resetZoom) {
    viewport?.setZoom(1);
  }
};

export async function ChangeOrientation(
  orientation: ImageOrientation,
  activeLesion: Lesions | null,
) {
  const engine = getRenderingEngine(RENDERING_ENGINE_ID);
  if (!engine) {
    throw new Error('Rendering engine not found');
  }

  const windowingValues = calculateWindowingValues();
  const viewportId = VIEWPORT_ID;
  const viewport = getViewport();
  if (!viewport) {
    throw new Error('Viewport not found');
  }

  const viewportInput: any = {
    viewportId,
    type: Enums.ViewportType.ORTHOGRAPHIC,
    element: getViewport().element,
    defaultOptions: {
      orientation,
    },
  };

  const volumeActors = viewport.getActors();
  const volumeIds = volumeActors.map((actor) => actor.uid);

  const volumeSettings = volumeIds.map((volumeId) => ({
    volumeId,
    callback: async ({ volumeActor }) => {
      if (!volumeActor) {
        console.error('Volume actor not found');
        return;
      }
      // Use the imported function to set the transfer function
      setTransferFunctionForVolumeActor(
        volumeActor,
        windowingValues.windowCenter,
        windowingValues.windowWidth,
      );
    },
  }));

  engine.setViewports([viewportInput]);

  try {
    await setVolumesForViewports(engine, volumeSettings, [viewportId]);
    const { currentSlice } = calculateSliceValues();
    await utilities.jumpToSlice(viewport.element, { imageIndex: currentSlice });

    const toolGroup = ToolGroupManager.getToolGroup(TOOL_GROUP_ID);
    if (toolGroup && getViewport()) {
      toolGroup.addViewport(viewportId, engine.id);
    }
    if (activeLesion) {
      applyColorMaps(activeLesion);
    } else {
      removeColorMaps();
    }

    document.dispatchEvent(new CustomEvent('orientationChangeComplete'));
    return true;
  } catch (error) {
    console.error('Failed to change orientation:', error);
    throw error;
  }
}

export const evaluateHeatMap = (heatMapEnabled: boolean) => {
  if (heatMapEnabled) {
    applyColorMaps();
  } else {
    removeColorMaps();
  }
};
