import UIFullScreenContent from 'components/ui/screenContent/FullScreenContent';
import React from 'react';

const GeneralErrorPage = () => (
  <UIFullScreenContent
    header='Something went wrong.'
    description={<>Please contact Methinks Support</>}
    useSymbol={true}
  />
);

export default GeneralErrorPage;
