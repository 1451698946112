import { Enums, getRenderingEngine } from '@cornerstonejs/core';
import { Enums as ToolEnums } from '@cornerstonejs/tools';
import { useLesion } from 'context/Lesions';
import { useWindowing } from 'context/Windowing';
import { useCallback, useEffect, useRef } from 'react';
import { activateWindowLevelTool,
  calculateSliceValues,
  calculateWindowingValues,
  getAndSetWindowingValues } from 'utils/cornerstone/tooling';
import { applyDefaultColorMap, getDefaultLesion } from 'utils/heatmaps/heatmaps';
import { getDefaultWindowingValues, getViewport } from 'utils/utilities';
import { RENDERING_ENGINE_ID } from '../constants';

export default function useToolingEffects(elementRef, setSliderValue) {
  const { setActiveLesion } = useLesion();
  const { setWindowingValues } = useWindowing();
  const isHeatMapInitialized = useRef(false);

  const handleDrag = useCallback(() => {
    getAndSetWindowingValues(setWindowingValues);
  }, []);
  const handleTouchStart = useCallback(() => {
    activateWindowLevelTool(setWindowingValues);
  }, []);

  useEffect(() => {
    const defaultLesion = getDefaultLesion();
    const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
    if (renderingEngine && !isHeatMapInitialized.current) {
      applyDefaultColorMap();
      setActiveLesion(defaultLesion);
      renderingEngine.render();
      isHeatMapInitialized.current = true;
    }
  }, [getDefaultLesion()]);

  const handleCameraReset = useCallback(() => {
    const viewport = getViewport();
    if (viewport) {
      const windowingValues = calculateWindowingValues()
        || getDefaultWindowingValues(viewport?.getActors()[0]?.uid);
      setWindowingValues(windowingValues);

      const { currentSlice, maxSlice } = calculateSliceValues();
      setSliderValue({ currentSlice, maxSlice });
    }
  }, [setSliderValue, setWindowingValues]);

  const eventListeners = {
    [ToolEnums.Events.TOUCH_START]: handleTouchStart,
    [ToolEnums.Events.MOUSE_DOWN]: handleTouchStart,
    [ToolEnums.Events.MOUSE_DRAG]: handleDrag,
    [ToolEnums.Events.TOUCH_DRAG]: handleDrag,
    [Enums.Events.CAMERA_RESET]: handleCameraReset,
  };

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    Object.entries(eventListeners).forEach(([event, handler]) => {
      element.addEventListener(event, handler);
    });

    // eslint-disable-next-line
    return () => {
      Object.entries(eventListeners).forEach(([event, handler]) => {
        element.removeEventListener(event, handler);
      });
    };
  }, [elementRef, eventListeners]);

  return handleDrag;
}
