import React from 'react';

export const ExclamationBlueIcon: React.JSX.Element = (
  <>
    <svg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00008 9.97917C6.72408 9.97917 6.50008 9.77117 6.50008 9.4945V9.46317C6.50008 9.18717 6.72408 8.96317 7.00008 8.96317C7.27608 8.96317 7.50008 9.18717 7.50008 9.46317C7.50008 9.73917 7.27608 9.97917 7.00008 9.97917ZM6.49742 4.5385C6.49742 4.2625 6.72142 4.0385 6.99742 4.0385C7.27342 4.0385 7.49742 4.2625 7.49742 4.5385V7.4045C7.49742 7.6805 7.27342 7.9045 6.99742 7.9045C6.72142 7.9045 6.49742 7.6805 6.49742 7.4045V4.5385ZM7.00008 0.666504C3.50808 0.666504 0.666748 3.50784 0.666748 6.99984C0.666748 10.4925 3.50808 13.3332 7.00008 13.3332C10.4921 13.3332 13.3334 10.4925 13.3334 6.99984C13.3334 3.50784 10.4921 0.666504 7.00008 0.666504Z'
        fill='#77A3F9'
      />
    </svg>
  </>
);

export default ExclamationBlueIcon;
