import React from 'react';

const DateIcon: React.JSX.Element = (
  <>
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_745_33721)">
        <path
          d="M13.125 4.6875V3.75C13.125 3.41848 12.9933 3.10054 12.7589 2.86612C12.5245 2.6317 12.2065 2.5 11.875 2.5H3.125C2.79348 2.5 2.47554 2.6317 2.24112 2.86612C2.0067 3.10054 1.875 3.41848 1.875 3.75V12.5C1.875 12.8315 2.0067 13.1495 2.24112 13.3839C2.47554 13.6183 2.79348 13.75 3.125 13.75H5.3125"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 1.25V3.75"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 1.25V3.75"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.875 6.25H5"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9375 10.9375L10 10.1562V8.75"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.75 10C13.75 10.9946 13.3549 11.9484 12.6517 12.6517C11.9484 13.3549 10.9946 13.75 10 13.75C9.00544 13.75 8.05161 13.3549 7.34835 12.6517C6.64509 11.9484 6.25 10.9946 6.25 10C6.25 9.00544 6.64509 8.05161 7.34835 7.34835C8.05161 6.64509 9.00544 6.25 10 6.25C10.9946 6.25 11.9484 6.64509 12.6517 7.34835C13.3549 8.05161 13.75 9.00544 13.75 10Z"
          stroke="#A6B2CB"
          strokeWidth="0.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_745_33721">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default DateIcon;
