import { Skeleton as MuiSkeleton, Box } from '@mui/material';
import React from 'react';
import './Skeleton.css';

export const Skeleton: React.FC = () => (
    <Box className='skeleton-full-screen'>
      {/* Content */}
      {[...Array(3)].map((_, index) => (
        <MuiSkeleton
          key={index}
          variant='rectangular'
          className='skeleton-items small-skeleton'
        />
      ))}
      <MuiSkeleton
        variant='rectangular'
        className='skeleton-items selection-item-large'
      />
    </Box>
);

export default Skeleton;
