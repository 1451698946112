import React from 'react';

const OrientationIcon: React.FC = () => (
  <div>
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4095_4691)'>
        <path
          d='M13.4581 16.4248C13.4581 15.5871 13.4581 13.6448 13.4581 12.6395C13.4581 11.6629 15.7681 9.90334 15.61 7.12212M5.69438 3.21446C7.39497 1.5629 11.3059 0.734583 13.7931 3.21446M6.98833 16.4249V14.0477C6.98833 13.4194 5.69437 13.8961 5.04739 13.2678C4.39521 12.6344 4.22039 11.467 4.34257 10.4793C4.36527 10.2959 4.2268 10.1271 4.04281 10.1093C3.08333 10.0165 2.22559 9.52438 3.10646 8.2412C3.37897 7.84422 3.5654 7.47511 3.70804 7.12212'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='1.7999'
          y1='5.2748'
          x2='16.9999'
          y2='5.27481'
          stroke='white'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeDasharray='1.5 2.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_4095_4691'>
          <rect width='18' height='18' fill='white' transform='translate(0.399902)' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default OrientationIcon;
