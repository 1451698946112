import React from 'react';

const WindowingActivatedIcon: React.FC = () => (
  <>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_313_13349)'>
        <path
          d='M9.99993 18.3631C14.5186 18.3631 18.1818 14.7 18.1818 10.1813C18.1818 5.66264 14.5186 1.99951 9.99993 1.99951C5.48124 1.99951 1.81812 5.66264 1.81812 10.1813C1.81812 14.7 5.48124 18.3631 9.99993 18.3631Z'
          stroke='#77A3F9'
          strokeWidth='1.63636'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 15.0906C11.302 15.0906 12.5506 14.5734 13.4713 13.6528C14.3919 12.7322 14.9091 11.4835 14.9091 10.1816C14.9091 8.87958 14.3919 7.63093 13.4713 6.7103C12.5506 5.78967 11.302 5.27246 10 5.27246V15.0906Z'
          fill='#77A3F9'
          stroke='#77A3F9'
          strokeWidth='1.63636'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_313_13349'>
          <rect width='19.6364' height='19.6364' fill='white' transform='translate(0.181763 0.363281)' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default WindowingActivatedIcon;
