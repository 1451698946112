import React from 'react';
import './Support.css';

const Support: React.FC = () => (
  <div className='help-container'>
    <h2>Need help?</h2>
    <button
      className='help-button'
      onClick={() => {
        window.open('https://www.methinks.ai/instruction-for-use', '_blank');
      }}
    >
      Read Instructions for Use
    </button>
    <button
      className='help-button'
      onClick={() => {
        window.open('https://methinksai.atlassian.net/servicedesk/customer/portal/2', '_blank');
      }}
    >
      Contact Methinks Support
    </button>
    <p className='urgent-text'>For urgent issues:</p>
    <button
      className='urgent-button'
      onClick={() => {
        window.location.href = 'tel:+34935240607';
      }}
    >
      Call Methinks Support
      <span className='phone-number'>+34 93 524 0607</span>
    </button>
  </div>
);

export default Support;
