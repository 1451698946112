import React from 'react';

const ActiveOrientationIcon: React.FC = () => (
  <div>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4097_1325)'>
        <path
          d='M14.4272 18.2812C14.4272 17.3673 14.4272 15.2484 14.4272 14.1517C14.4272 13.0864 16.9472 11.1668 16.7747 8.13278M5.9577 3.86988C7.81289 2.06817 12.0794 1.16456 14.7927 3.86988M7.36929 18.2812V15.688C7.36929 15.0025 5.95769 15.5226 5.2519 14.8372C4.53332 14.1393 4.34596 12.8472 4.4871 11.7629C4.51096 11.5796 4.37257 11.4107 4.18843 11.3945C3.1288 11.3013 2.16365 10.7679 3.13451 9.35359C3.4318 8.92053 3.63517 8.51786 3.79078 8.13278'
          stroke='#77A3F9'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='1.65'
          y1='6.17666'
          x2='18.35'
          y2='6.17666'
          stroke='#77A3F9'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeDasharray='1.5 3'
        />
      </g>
      <defs>
        <clipPath id='clip0_4097_1325'>
          <rect width='19.6364' height='19.6364' fill='white' transform='translate(0.181641 0.363281)' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default ActiveOrientationIcon;
