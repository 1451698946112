import { useLesion } from 'context/Lesions';
import useAdvancedMenu from 'hooks/useAdvancedMenu';
import React from 'react';
import { ProductResult } from 'types';
import { ChangeOrientation } from 'utils/utilities';
import { NIFTI_ORIENTATIONS } from '../../../constants';
import FunctionalText from '../../ui/text/FunctionalText';
import '../TopNav.css';

type AdvancedTopNavProps = {
  productResult: ProductResult;
};
export default function AdvancedTopNav({ productResult }: AdvancedTopNavProps) {
  const { activeLesion } = useLesion();
  const [, toggleAdvancedMenu] = useAdvancedMenu();
  const handleBackClick = async () => {
    toggleAdvancedMenu();
    await ChangeOrientation(NIFTI_ORIENTATIONS.AXIAL, activeLesion);
  };
  const resultSummary = productResult.summary;

  return (
    <div className='top-nav-container'>
      <FunctionalText
        text='Back'
        className='blue-text'
        onClick={handleBackClick}
      />
      <span
        className={
          resultSummary.includes('Suspected')
            ? 'results-accordion-header-warning'
            : 'results-accordion-header'
        }
      >
        {resultSummary}
      </span>
    </div>
  );
}
