import React, { createContext, useContext, useState } from 'react';
import { Lesions } from 'types';

interface LesionContextType {
  activeLesion: Lesions | null;
  setActiveLesion: React.Dispatch<React.SetStateAction<Lesions | null>>;
}

const LesionContext = createContext<LesionContextType | null>(null);

export const LesionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeLesion, setActiveLesion] = useState<Lesions | null>(null);

  return (
    <LesionContext.Provider value={{ activeLesion, setActiveLesion }}>
      {children}
    </LesionContext.Provider>
  );
};

export const useLesion = (): LesionContextType => {
  const context = useContext(LesionContext);
  if (!context) {
    throw new Error('useLesion must be used within a LesionProvider');
  }
  return context;
};
