import { settings, appInitialization } from '@microsoft/teams-js';

export default function handleConfigureMode() {
  const configUrl = `${process.env.REACT_APP_VIEWER_ENDPOINT}/?help`;
  const onSaveHandler = (saveEvent: settings.SaveEvent) => {
    settings.setSettings({
      contentUrl: configUrl,
      websiteUrl: configUrl,
      suggestedDisplayName: 'Methinks Help',
      entityId: 'Methinks Help',
    });
    saveEvent.notifySuccess();
  };

  settings.registerOnSaveHandler(onSaveHandler);
  settings.setValidityState(true);
  appInitialization.notifySuccess();
}
