/* eslint-disable */
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import { ImageInfo, Lesions, Product } from 'types';
import { getViewport } from 'utils/utilities';
import { LESIONS } from '../../constants';
type VolumeActor = any;
type RGB = [number, number, number];

const getVolumeActor = (actorType: string): VolumeActor | null => {
  const viewport = getViewport();
  if (!viewport) return null;

  const actors = viewport.getActors();
  const actor = actors.find((actor) => actor.uid.includes(actorType === 'ischemia' ? 'acute' : actorType));

  return actor || null;
};

export const getDataRange = (volumeActor: VolumeActor): [number, number] | null => {
  const scalars = volumeActor.actor.getMapper()?.getInputData()?.getPointData().getScalars();
  return scalars ? scalars.getRange(0) : null;
};

const applyColorTransferFunction = (volumeActor: VolumeActor, color?: RGB) => {
  const cfun = vtkColorTransferFunction.newInstance();
  const range = getDataRange(volumeActor);
  if (!range) return;

  if (color) {
    // For specific colors (clot and hemo), use the provided color
    cfun.addRGBPoint(range[0], ...color);
    cfun.addRGBPoint(range[1], ...color);
  } else {
    // For acute (ischemia), use a traditional heatmap color range

    // Define color points for the Jet palette
    const colorPoints = [
      { value: 0.0, color: [0/255, 0/255, 127/255] },   // #00007F: dark blue
      { value: 0.125, color: [0/255, 0/255, 255/255] }, // #0000FF: blue
      { value: 0.25, color: [0/255, 127/255, 255/255] },// #007FFF: azure
      { value: 0.375, color: [0/255, 255/255, 255/255] },// #00FFFF: cyan
      { value: 0.5, color: [127/255, 255/255, 127/255] },// #7FFF7F: light green
      { value: 0.625, color: [255/255, 255/255, 0/255] },// #FFFF00: yellow
      { value: 0.75, color: [255/255, 127/255, 0/255] },// #FF7F00: orange
      { value: 0.875, color: [255/255, 0/255, 0/255] }, // #FF0000: red
      { value: 1.0, color: [127/255, 0/255, 0/255] },   // #7F0000: dark red
    ];

    colorPoints.forEach((point) => {
      const value = range[0] + point.value * (range[1] - range[0]);
      cfun.addRGBPoint(value, point.color[0], point.color[1], point.color[2]);
    });
  }

  volumeActor.actor.getProperty().setRGBTransferFunction(0, cfun);
};
const applyOpacityFunction = (volumeActor: VolumeActor, customPoints?: [number, number][]) => {
  const ofun = vtkPiecewiseFunction.newInstance();
  (
    customPoints || [
      [0, 0],
      [0.3, 1],
      [5, 1],
    ]
  ).forEach(([x, y]) => ofun.addPoint(x, y));
  volumeActor.actor.getProperty().setScalarOpacity(0, ofun);
};

export const setHeatmap = (actorType: string, color?: RGB, isActive: boolean = true) => {
  const volumeActor = getVolumeActor(actorType);
  if (!volumeActor) return;

  if (isActive) {
    volumeActor.actor.getMapper().setSampleDistance(1.0);
    applyColorTransferFunction(volumeActor, color);
    applyOpacityFunction(volumeActor);
  } else {
    // Set opacity to 0 for inactive volumes
    const opacityFunction = vtkPiecewiseFunction.newInstance();
    opacityFunction.addPoint(0, 0);
    opacityFunction.addPoint(1, 0);
    volumeActor.actor.getProperty().setScalarOpacity(0, opacityFunction);
  }
};

const colorMap: Record<Lesions, { type: string; color: RGB | undefined }> = {
  clot: { type: 'clot', color: [1, 0.843, 0] },
  hemo: { type: 'hemo', color: [1, 0.12, 0.12] },
  ischemia: { type: 'acute', color: undefined },
};

export const applyColorMaps = (activeLesionType?: Lesions) => {
  Object.values(colorMap).forEach(({ type, color }) => {
    const isActive = activeLesionType ? type === colorMap[activeLesionType].type : true;
    setHeatmap(type, color, isActive);
  });
};

export const removeColorMaps = () => {
  getViewport()
    .getActors()
    .forEach((actor) => {
      if (['clot', 'hemo', 'acute'].some((type) => actor.uid.includes(type))) {
        const actorType = ['clot', 'hemo', 'acute'].find((type) => actor.uid.includes(type));
        setHeatmap(actorType, undefined, false);
      }
    });
};

export const getDefaultLesion = (): Lesions | null => {
  const acute = getVolumeActor('acute');
  const hemo = getVolumeActor('hemo');
  const clot = getVolumeActor('clot');

  if (acute) return LESIONS.ISCHEMIA;
  if (hemo) return LESIONS.HEMO;
  if (clot) return LESIONS.CLOT;

  return null;
};

export const applyDefaultColorMap = () => {
  const defaultLesion = getDefaultLesion();
  if (defaultLesion) {
    hasDisplayableValues('acute');
    removeColorMaps();
    const { type, color } = colorMap[defaultLesion];
    setHeatmap(type, color);
  }
};

export const removeColorMap = (volumeActor: VolumeActor) => {
  const colorFunction = vtkColorTransferFunction.newInstance();
  const opacityFunction = vtkPiecewiseFunction.newInstance();

  colorFunction.addRGBPoint(0, 0, 0, 0);
  opacityFunction.addPoint(0, 0);
  opacityFunction.addPoint(1, 1);

  volumeActor.actor.getProperty().setRGBTransferFunction(0, colorFunction);
  volumeActor.actor.getProperty().setScalarOpacity(0, opacityFunction);
};

export const hasDisplayableValues = (actorType: string): boolean => {
  const volumeActor = getVolumeActor(actorType);
  if (!volumeActor) return false;

  const range = getDataRange(volumeActor);
  if (!range) return false;
  const [min, max] = range;
  return min !== max && !isNaN(min) && !isNaN(max);
};

export const getDisplayableLesions = (): Lesions[] => {
  const displayableLesions: Lesions[] = [];

  Object.entries(colorMap).forEach(([lesion, { type }]) => {
    if (hasDisplayableValues(type)) {
      displayableLesions.push(lesion as Lesions);
    }
  });

  return displayableLesions;
};

export function shouldDisplayLesionMenu(
  selectedProduct: Product,
  imageType: ImageInfo['type'],
): boolean {
  return selectedProduct === 'res' && imageType === 'NCCT';
}
