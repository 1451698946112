import { TableCell } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import './Table.css';

type TableProps = {
  className?: string;
  data: Array<{
    icon?: React.ReactNode;
    label: string;
    value: string | number;
  }>;
};

export default function UITable({ className, data }: TableProps) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell className={`first-cell ${className}`}>
                <span className='icon-wrapper'>{item.icon}</span>
                {item.label}
              </TableCell>
              <TableCell sx={{ color: 'text.secondary' }} className={className}>
                {item.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
