function formatISO8601Date(isoDateString: string): string {
  const dateObj = new Date(isoDateString);
  return dateObj
    .toLocaleString('en-GB', {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .replace(',', '').toUpperCase();
}

export default formatISO8601Date;
