import React from 'react';
import './IconMenu.css';

type MenuButton = {
  icon: React.ReactNode | React.ElementType;
  onClick?: () => void;
};

type DefaultMenuProps = {
  buttons: MenuButton[];
  rightIcon?: MenuButton;
};

const renderIcon = (icon: React.ReactNode | React.ElementType) => {
  if (React.isValidElement(icon)) {
    return icon;
  }
  if (typeof icon === 'function') {
    return React.createElement(icon);
  }
  return null;
};

export default function UIIconMenu({ buttons, rightIcon }: DefaultMenuProps) {
  return (
    <div className='top-bar'>
      <div className='space-buttons'>
        <div className='top-bar-left-group'>
          {buttons.map((button, index) => (
            <div key={index} className='top-bar-button' onClick={button?.onClick}>
              {renderIcon(button.icon)}
            </div>
          ))}
        </div>
        {rightIcon && <div className='top-bar-button top-bar-right' onClick={rightIcon.onClick}>{renderIcon(rightIcon.icon)}</div>}
      </div>
    </div>
  );
}
