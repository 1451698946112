import ImageDropdown from 'components/imagedropdown/ImageDropdown';
import PatientInfo from 'components/patientinfo/PatientInfo';
import Results from 'components/results/Result';
import AdvancedTopNav from 'components/topnav/advanced/AdvancedTopNav';
import Viewer from 'components/viewer/Viewer';
import useAdvancedMenu from 'hooks/useAdvancedMenu';
import React, { useState } from 'react';
import { CurrentCase } from 'types';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import formatImageData from 'utils/mapping/formatImageData';
import { DEVICE_ORIENTATION } from '../constants';
import './MainView.css';

export default function MainView({ currentCase }: { currentCase: CurrentCase }) {
  const [isAdvancedNavOpen] = useAdvancedMenu();

  const { patient } = currentCase;
  const selectedProduct = currentCase.selected_product;
  const selectedImage = currentCase.selected_image;
  const niftiUrl = currentCase.nifti_urls;
  const studyDatetime = currentCase.study_datetime;
  const initialVolumeId = `nifti:${niftiUrl[parseInt(selectedImage, 10)].image}`;

  const isDesktop = window.screen.availWidth > 950
    && window.screen.availHeight < window.screen.availWidth;
  const [showLesionMenu, setShowLesionMenu] = useState(
    shouldDisplayLesionMenu(
      currentCase.selected_product,
      currentCase.images[currentCase.selected_product][currentCase.selected_image].type,
    ),
  );
  const productResult = currentCase.product_results[currentCase.selected_product];
  const { images } = currentCase;
  const lesionVolumeIds = Object.values(
    currentCase.nifti_urls[currentCase.selected_image].lesions || {},
  )
    .flatMap((lesionGroup) => Object.values(lesionGroup))
    .filter(Boolean)
    .map((url) => `nifti:${url}`);

  const initialVolumeIds = {
    image: initialVolumeId,
    lesions: lesionVolumeIds,
  };
  const formattedImages = formatImageData(
    images,
    selectedProduct,
    niftiUrl,
    selectedImage,
    setShowLesionMenu,
  );

  const evaluateDeviceOrientation = () => (window.innerHeight > window.innerWidth
    ? DEVICE_ORIENTATION.PORTRAIT
    : DEVICE_ORIENTATION.LANDSCAPE);
  const [deviceOrientation, setDeviceOrientation] = React.useState<DEVICE_ORIENTATION>(
    evaluateDeviceOrientation(),
  );

  React.useEffect(() => {
    const handleResizeDimensions = () => {
      if (!isDesktop) {
        setDeviceOrientation(evaluateDeviceOrientation());
      }
    };

    window.addEventListener('resize', handleResizeDimensions);

    return () => {
      window.removeEventListener('resize', handleResizeDimensions);
    };
  }, []);

  const showMenus = deviceOrientation === DEVICE_ORIENTATION.PORTRAIT || isDesktop;

  return (
    <div className='main-view'>
      {showMenus && (
        <div className='main-view-menus'>
          {isAdvancedNavOpen ? (
            <AdvancedTopNav productResult={productResult} />
          ) : (
            <div className='normal-menus main-view-menus'>
              <PatientInfo patientData={patient} studyDate={studyDatetime} />
              <Results productResult={productResult} />
            </div>
          )}
          <ImageDropdown formattedImages={formattedImages} />
        </div>
      )}
      <Viewer initialVolumeIds={initialVolumeIds} showLesionMenu={showLesionMenu} />
    </div>
  );
}
