import React from 'react';

const ResetIcon: React.FC = () => (
  <>
    <svg width='18' height='18' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.97217 1.83301V7.33301H8.47217'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.97217 11.9163C3.16681 13.7551 3.97352 15.4752 5.26285 16.8005C6.55218 18.1259 8.24938 18.9797 10.0821 19.225C11.9148 19.4702 13.7768 19.0927 15.3692 18.1529C16.9616 17.2132 18.1922 15.7658 18.8635 14.0429C19.5348 12.32 19.6078 10.4215 19.0708 8.65213C18.5339 6.88276 17.4181 5.34507 15.9025 4.2858C14.387 3.22653 12.5595 2.7071 10.7134 2.81086C8.86724 2.91462 7.10949 3.63555 5.72217 4.85798L2.97217 7.33298'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
);

export default ResetIcon;
