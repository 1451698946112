import InfoModal from 'components/Modals/infomodal/Infomodal';
import { AxialOrientation,
  CoronalOrientation,
  ExpandIcon,
  InfoIcon,
  ResetIcon,
  SagittalOrientation,
  WindowingIcon } from 'components/ui/icons';
import UIIconMenu from 'components/ui/menus/icon/IconMenu';
import UiModal from 'components/ui/modal/Modal';
import { useWindowing } from 'context/Windowing';
import useAdvancedMenu from 'hooks/useAdvancedMenu';
import React from 'react';
import { resetFilters, getViewport } from 'utils/utilities';
import OrientationMenu from './OrientationMenu';
import WindowingMenu from './WindowingMenu';

type defaultMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const DefaultCanvasMenu = ({ setTopBar }: defaultMenuProps) => {
  const [advancedMenu, toggleAdvancedMenu] = useAdvancedMenu();

  const { setWindowingValues } = useWindowing();
  const viewPort = getViewport();
  const orientation = viewPort?.defaultOptions?.orientation || 'axial';
  const iconMap = {
    axial: AxialOrientation,
    sagittal: SagittalOrientation,
    coronal: CoronalOrientation,
  };
  const conditionalIcons = advancedMenu
    ? [{
      icon: iconMap[orientation],
      onClick: () => setTopBar(<OrientationMenu setTopBar={setTopBar} />),
    }]
    : [];

  const buttons = [
    {
      icon: <UiModal icon={<InfoIcon />} content={<InfoModal />} />,
    },
    {
      icon: WindowingIcon,
      onClick: () => setTopBar(<WindowingMenu setTopBar={setTopBar} />),
    },
    ...conditionalIcons,
    {
      icon: ResetIcon,
      onClick: () => resetFilters(setWindowingValues),
    },
  ].filter(Boolean);

  return (
    <UIIconMenu
      buttons={buttons}
      rightIcon={
        !advancedMenu && {
          icon: ExpandIcon,
          onClick: toggleAdvancedMenu,
        }
      }
    />
  );
};

export default DefaultCanvasMenu;
