import React from 'react';

const CoronalOrientation: React.FC = () => (
  <div>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1310_67474)'>
        <line
          x1='10.6501'
          y1='1.24082'
          x2='10.6501'
          y2='18.759'
          stroke='#FFFFFF'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeDasharray='1.23 2.7'
        />
        <path
          d='M14.5744 17.9571C14.5744 17.0433 14.5744 14.9245 14.5744 13.8279C14.5744 12.4572 18.7469 9.67206 15.9858 4.91799C15.2884 3.71713 14.3776 2.91931 13.3806 2.43757M7.51731 17.9571V15.364C7.51731 14.6786 6.10588 15.1986 5.40016 14.5133C4.6693 13.8035 4.48803 12.4788 4.64303 11.3836C4.66486 11.2293 4.54831 11.087 4.39296 11.0756C3.31196 10.9964 2.29515 10.4694 3.28302 9.03033C4.69448 6.97423 3.98875 5.60336 6.10588 3.54726C6.62286 3.04518 7.32711 2.61286 8.13281 2.30896'
          stroke='#FFFFFF'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1310_67474'>
          <rect width='19.6364' height='19.6364' fill='white' transform='translate(0.181824 0.181763)' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default CoronalOrientation;
