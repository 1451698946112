import ResultsAccordion from 'components/results/accordion/ResultAccordion';
import ResultsTable from 'components/results/table/ResultTable';
import { ExclamationBlueIcon, ExclamationRedIcon } from 'components/ui/icons';
import React from 'react';
import { ProductResult } from 'types';

export default function Results({ productResult }: { productResult: ProductResult }) {
  const { summary, ...results } = productResult;

  const tableData = Object.entries(results).map(([key, value]) => ({
    label: key
      .split('_')
      .map((word) => word.toUpperCase())
      .join(' '),
    value: value.agg_result,
  }));

  const isWarning = summary.includes('Suspected');

  return (
    <div>
      <ResultsAccordion
        className={`results-accordion-header${isWarning ? '-warning' : ''}`}
        icon={isWarning ? ExclamationRedIcon : ExclamationBlueIcon}
        header={{ value: summary }}
        detailedComponent={<ResultsTable data={tableData} />}
      />
    </div>
  );
}
