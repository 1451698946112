import { KeyboardArrowDown } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import './ResultAccordion.css';

type AccordionProps = {
  className?: string;
  icon?: React.ReactNode;
  header: { value: string; expandedValue?: string };
  detailedComponent: React.ReactNode;
};

export default function ResultAccordion({
  className,
  icon,
  header,
  detailedComponent,
}: AccordionProps) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      slotProps={{
        transition: {
          timeout: 600,
        },
      }}
      sx={{
        backgroundColor: 'background.default',
      }}
      className={className}
    >
      <AccordionSummary
        className='results-accordion-header'
        onClick={handleExpand}
        expandIcon={
          <KeyboardArrowDown
            sx={{
              color: 'text.primary',
            }}
          />
        }
      >
        <Typography
          className='accordion-header results-accordion-header'
          sx={{
            color: 'text.primary',
            fontWeight: 'normal',
          }}
        >
          {icon && <span className='exclamation-icon'>{icon}</span>}
          Results
          <span>|</span>
          <span className={`${className}`}>{header.value}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className='accordion-details'
        sx={{
          color: 'text.primary',
        }}
      >
        {detailedComponent || <Typography sx={{ color: 'text.primary' }}>No details available.</Typography>}
      </AccordionDetails>
    </Accordion>
  );
}
