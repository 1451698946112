import React from 'react';

const WindowingIcon: React.FC = () => (
  <>
    <svg width='18' height='18' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1909_4118)'>
        <path
          d='M11.1111 20.1663C16.1737 20.1663 20.2778 16.0623 20.2778 10.9997C20.2778 5.93706 16.1737 1.83301 11.1111 1.83301C6.04851 1.83301 1.94446 5.93706 1.94446 10.9997C1.94446 16.0623 6.04851 20.1663 11.1111 20.1663Z'
          stroke='white'
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.1111 16.5C12.5698 16.5 13.9687 15.9205 15.0002 14.8891C16.0316 13.8576 16.6111 12.4587 16.6111 11C16.6111 9.54131 16.0316 8.14236 15.0002 7.11091C13.9687 6.07946 12.5698 5.5 11.1111 5.5V16.5Z'
          fill='white'
          stroke='white'
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1909_4118'>
          <rect width='22' height='22' fill='white' transform='translate(0.111084)' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default WindowingIcon;
