import { KeyboardArrowDown } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import './Accordion.css';

export default function UIAccordion({
  icon,
  header,
  detailedComponent,
}: {
  icon: React.ReactNode;
  header: { value: string; expandedValue: string | undefined };
  detailedComponent: React.ReactNode;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [headerText, setHeaderText] = React.useState(header.value);

  const handleExpand = () => {
    setExpanded(!expanded);
    setHeaderText(!expanded && header.expandedValue ? header.expandedValue : header.value);
  };

  return (
    <Accordion
      sx={{ backgroundColor: 'background.default' }}
      slotProps={{
        transition: {
          timeout: 600,
        },
      }}
    >
      <AccordionSummary
        onClick={handleExpand}
        expandIcon={
          <KeyboardArrowDown
            className='accordion-icon'
            sx={{
              color: 'text.primary',
            }}
          />
        }
      >
        <Typography
          className='accordion-header'
          sx={{
            color: expanded ? 'text.primary' : 'text.secondary',
            fontWeight: icon ? 'normal' : 'medium',
          }}
        >
          {icon && <span className='accordion-icon'>{icon}</span>}
          {headerText}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          color: 'text.primary',
        }}
      >
        {detailedComponent || <Typography sx={{ color: 'text.primary' }}>No details available.</Typography>}
      </AccordionDetails>
    </Accordion>
  );
}
