import { TableCell } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import './ResultTable.css';

type TableProps = {
  data: Array<{
    label: string;
    value: string | number;
  }>;
};

export default function UITable({ data }: TableProps) {
  function getClassLabel(result: string) {
    if (result.toLowerCase().includes('suspected')) {
      return 'suspected-label';
    }
    if (result.toLowerCase() === 'analysis complete') {
      return 'analysis-complete-label';
    }
    if (result.toLowerCase().includes('inconsistent')) {
      return 'inconsistent_result_label';
    }
    return '';
  }

  return (
    <TableContainer className='results-table'>
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} className='results-table'>
              <TableCell className={'results-table'}>{item.label}</TableCell>
              <TableCell sx={{ color: 'text.secondary' }} className='results-table'>
                <span className={`${getClassLabel(item.value.toString())} results-label`}>{item.value}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
