import UIDropdown from 'components/ui/dropdown/Dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { loadNewVolume } from 'utils/cornerstone/setup';
import { formattedImageData, VolumeIds } from 'types';

export const handleDropdownItemClick = async (imageMetaData: VolumeIds) => {
  await loadNewVolume(imageMetaData);
};

type ImageDropdownProps = {
  formattedImages: formattedImageData[];
};

const ImageDropdown = ({ formattedImages }: ImageDropdownProps) => {
  const indexRef = useRef(formattedImages.findIndex((img) => img.selectedImage));
  const [activeIndex, setActiveIndex] = useState(indexRef.current);
  useEffect(() => {
    indexRef.current = activeIndex;
  }, [activeIndex]);

  return (
    <UIDropdown items={formattedImages} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
  );
};

export default ImageDropdown;
