import React from 'react';

const ActiveSagittalOrientation: React.FC<{
  className?: string;
}> = ({ className }) => (
    <div className={className}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line
          x1='9.91361'
          y1='1.64988'
          x2='9.91361'
          y2='18.3499'
          stroke='#77A3F9'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeDasharray='1.23 2.7'
        />
        <path
          d='M13.2248 17.8033C13.2248 17.0232 13.2248 16.6673 13.2248 15.9244M13.2248 15.9244C13.2149 15.8237 13.2287 16.0343 13.2248 15.9244ZM13.2248 15.9244C14.2755 15.3135 15.554 13.864 15.8138 12.5771C16.2105 12.5268 16.6068 12.5424 16.9826 11.4151C17.0925 11.0853 17.408 10.4362 17.3583 9.91196C17.2944 9.23708 16.5837 9.24261 16.276 9.11201M16.276 9.11201C16.2764 9.07144 16.276 9.1499 16.276 9.11201ZM16.276 9.11201C16.276 8.2528 16.1957 7.01648 16.037 6.20385C16.0031 5.97963 15.9521 5.75386 15.8858 5.53231C15.5875 4.5367 14.9636 3.64206 14.1295 3.01286C13.6575 2.65688 13.0784 2.39297 12.4732 2.22717'
          stroke='#77A3F9'
          strokeWidth='1.50312'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.77522 17.8033C6.77522 17.0232 6.77522 16.6673 6.77522 15.9244M6.77522 15.9244C6.78507 15.8237 6.77128 16.0343 6.77522 15.9244ZM6.77522 15.9244C5.72455 15.3135 4.446 13.864 4.18623 12.5771C3.78947 12.5268 3.39322 12.5424 3.01744 11.4151C2.90751 11.0853 2.59199 10.4362 2.64166 9.91196C2.70557 9.23708 3.41633 9.24261 3.72403 9.11201M3.72403 9.11201C3.72359 9.07144 3.72403 9.1499 3.72403 9.11201ZM3.72403 9.11201C3.72403 8.2528 3.80434 7.01648 3.96301 6.20385C3.99693 5.97963 4.04792 5.75386 4.11423 5.53231C4.41251 4.5367 5.03644 3.64206 5.87046 3.01286C6.3425 2.65688 6.92165 2.39297 7.52678 2.22717'
          stroke='#77A3F9'
          strokeWidth='1.50312'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
);

export default ActiveSagittalOrientation;
