import { ActiveAxialOrientation,
  ActiveCoronalOrientation,
  ActiveSagittalOrientation,
  CrossIcon } from 'components/ui/icons';
import UISelectionMenu from 'components/ui/menus/selection/SelectionMenu';
import React from 'react';
import { ImageOrientation } from 'types';
import { ChangeOrientation, getViewport } from 'utils/utilities';
import { useLesion } from 'context/Lesions';
import { NIFTI_ORIENTATIONS } from '../../constants';
import DefaultMenuCanvas from './DefaultMenuCanvas';

type OrientationMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const OrientationMenu: React.FC<OrientationMenuProps> = ({ setTopBar }) => {
  const { activeLesion } = useLesion();
  const viewPort = getViewport();
  const imageOrientation = viewPort?.defaultOptions?.orientation || 'axial';

  const orientationOptions: Record<string, { title: string, icon: React.ReactNode }> = {
    axial: { title: NIFTI_ORIENTATIONS.AXIAL, icon: <ActiveAxialOrientation /> },
    sagittal: { title: NIFTI_ORIENTATIONS.SAGITTAL, icon: <ActiveSagittalOrientation /> },
    coronal: { title: NIFTI_ORIENTATIONS.CORONAL, icon: <ActiveCoronalOrientation /> },
  };

  const menuContent = Object.entries(orientationOptions).map(([orientation, { title }]) => ({
    title,
    orientation,
    onClick: () => ChangeOrientation(orientation as ImageOrientation, activeLesion),
  }));

  return (
    <UISelectionMenu
      menuContent={menuContent}
      activeIcon={orientationOptions[imageOrientation].icon}
      activeItem={imageOrientation}
      exitIcon={{
        icon: <CrossIcon />,
        onClick: () => setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />),
      }}
    />
  );
};

export default OrientationMenu;
