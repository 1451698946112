import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState } from 'react';
import './Dropdown.css';

export type DropdownItem = {
  label: string;
  onClick?: () => void;
};

type DropdownProps = {
  items: DropdownItem[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

const UIDropdown = ({ items, activeIndex, setActiveIndex }: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isSingleItemOrEmpty = items.length <= 1;

  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {items[activeIndex].label}
        {isDropdownOpen ? (
          <KeyboardArrowUp className={`dropdown-arrow ${isSingleItemOrEmpty ? 'transparent-arrow' : ''}`} />
        ) : (
          <KeyboardArrowDown className={`dropdown-arrow ${isSingleItemOrEmpty ? 'transparent-arrow' : ''}`} />

        )}
      </div>
      {isDropdownOpen && (
        <div className='dropdown-list'>
          {items.map((item, index) => (
            <div
              key={index}
              className={`dropdown-item ${index === activeIndex ? 'selected' : ''}`}
              onClick={() => {
                setIsDropdownOpen(false);
                if (index !== activeIndex) {
                  setActiveIndex(index);
                  item?.onClick?.();
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UIDropdown;
