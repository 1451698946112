import { Box } from '@mui/material';
import React from 'react';
import './Infomodal.css';

const InfoModal = () => (
  <div className='info-modal'>
    <Box className='modal-arrow'></Box>
    <Box className='info-modal-content'>
      <div>
        <span className='first-word'>Windowing: </span>
        Activate windowing tool and swipe your finger across the screen to adjust contrast.
      </div>
      <div>
        <span className='first-word'>Pan: </span> Swipe your finger across the screen to move the image.
      </div>
      <div>
        <span className='first-word'>Zoom: </span> Pinch your fingers in or out to zoom.
      </div>
    </Box>
  </div>
);

export default InfoModal;
