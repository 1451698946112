import React from 'react';

const InfoIcon: React.FC = () => (
  <>
    <svg width='18' height='18' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1909_4112)'>
        <path
          d='M11 20.1663C16.0626 20.1663 20.1667 16.0623 20.1667 10.9997C20.1667 5.93706 16.0626 1.83301 11 1.83301C5.93743 1.83301 1.83337 5.93706 1.83337 10.9997C1.83337 16.0623 5.93743 20.1663 11 20.1663Z'
          stroke='white'
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M11 14.6667V11' stroke='white' strokeWidth='1.83333' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M11 7.33301H11.0092'
          stroke='white'
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1909_4112'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default InfoIcon;
