import React from 'react';

const ExpandIcon: React.FC = () => (
  <>
    <svg width='18' height='18' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.25 19.25L13.75 13.75M19.25 19.25V14.85M19.25 19.25H14.85'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.75 14.85V19.25M2.75 19.25H7.15M2.75 19.25L8.25 13.75'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.25 7.15V2.75M19.25 2.75H14.85M19.25 2.75L13.75 8.25'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.75 7.15V2.75M2.75 2.75H7.15M2.75 2.75L8.25 8.25'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
);

export default ExpandIcon;
