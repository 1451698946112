import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './Modal.css';

type UiModalProps = {
  icon: React.ReactNode;
  content: React.ReactNode;
};

export default function UiModal({ icon, content }: UiModalProps) {
  const [open, setOpen] = React.useState(false);
  const [contentStyle, setContentStyle] = React.useState({});
  const buttonRef = React.useRef(null);

  const handleOpen = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setContentStyle({
        top: `${rect.top - 10}px`,
        left: `${rect.right - 10}px`,
      });
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button ref={buttonRef} className='action-button' onClick={handleOpen}>
        {icon}
      </Button>
      <Modal open={open} onClose={handleClose} className='modal-container'>
        <div style={contentStyle}>{content}</div>
      </Modal>
    </div>
  );
}
